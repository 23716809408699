* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	overflow-x: hidden;
}

.social-link svg {
	font-size: 4rem;
	margin: 10px;
}
